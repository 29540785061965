import { Button } from '@mui/material';
import Box from '@mui/material/Box';
import { useEffect, useState } from 'react';
import './AboutMeDiffModal.css';
import BasicModal from './BasicModal';

export function AboutMeDiffModal(props) {

    async function doNothing(val) { }

    return (
        <BasicModal open={props.open} onChange={doNothing} title="Journal entry saved. Update About Me?">
            <div className='AboutMeDiffModal-container'>
                <div className='AboutMeDiffModal-diff-row'>
                    <Box className='AboutMeDiffModal-diff-box'>{props.before}</Box>
                    <Box className='AboutMeDiffModal-diff-box'>{props.after}</Box>
                </div>
                <div className='AboutMeDiffModal-diff-row'>
                    <Button variant="contained" onClick={props.handleCancel} className='AboutMeDiffModal-button'>Cancel</Button>
                    <Button variant="contained" onClick={props.handleUpdate} className='AboutMeDiffModal-button'>Accept Update</Button>
                </div>
            </div>
        </BasicModal>
    );
}
