import BasicModal from './BasicModal';
import './Journal.css';
import { useState } from 'react';

export function WelcomeModal(props) {
    const [initModalOpen, setInitModalOpen] = useState(true);

    return
    (<BasicModal open={initModalOpen} onChange={setInitModalOpen} title="Welcome">
        <div className='WelcomeModal-container'>
            <div>
                Hello
            </div>
            <div>
                World
            </div>

        </div>
    </BasicModal>);
}