import { addDoc, collection, doc, getDoc, getFirestore, serverTimestamp, setDoc } from '@firebase/firestore';
import Button from '@mui/material/Button';
import { getFunctions, httpsCallable } from 'firebase/functions';
import { useEffect, useState } from 'react';
import { useFirebaseApp, useUser } from 'reactfire';
import AboutMeContainer from './AboutMeContainer';
import { AboutMeDiffModal } from './AboutMeDiffModal';
import { aboutMePath, promptPath } from './Constants';
import { EditAboutMeModal } from './EditAboutMeModal';
import './Journal.css';
import JournalText from './JournalText';
import PromptContainer from './PromptContainer';
import Title from './Title';
import { UserContext } from './UserContext';
import { WelcomeModal } from './WelcomeModal';

function Journal() {
  const app = useFirebaseApp();
  const firestore = getFirestore(app);
  const functions = getFunctions(app);
  const remotePrompt = httpsCallable(functions, 'prompt');

  const { userStatus, data: user } = useUser();

  const [aboutMe, setAboutMe] = useState("");

  const [journalText, setJournalText] = useState("");

  const [prompt, setPrompt] = useState("");

  const [aboutMeDiffOpened, setAboutMeDiffOpened] = useState(false);
  const [editAboutMeModalOpened, setEditAboutMeModalOpened] = useState(false);
  const [updatedAboutMe, setUpdatedAboutMe] = useState("");

  async function handleJournalSave() {
    // BUG: Why can't I pass the UID?? It fails to render because undefined, but here it is defined. 
    const collectionRef = collection(firestore, `/journal-entries/${user.uid}/journals/`);
    console.log(`journalText: ${JSON.stringify(journalText)}`)
    await updateAboutMe();
    addDoc(collectionRef, {
      text: journalText,
      submitted: serverTimestamp(),
      prompt: prompt,
      aboutMe: aboutMe,
      proposedAboutMeUpdate: updatedAboutMe,
    }).then((ref) => {
      console.log(`Saved journal text to :\n${ref.path}`);
      setJournalText("");
      setPrompt("");
    }).catch((e) => {
      console.log(`Failed to save journal: ${e}`);
    });
    promptDiffBeforeSave(aboutMe);
  }

  async function handleAboutMeUpdate() {
    // Apply the update to "About Me" and also persist it.
    setAboutMe(updatedAboutMe);
    saveAboutMe(updatedAboutMe);
    setAboutMeDiffOpened(false);
    await updatePrompt();
  }

  async function handleAboutMeCancel() {
    // Revert the update to "About Me"
    setUpdatedAboutMe(aboutMe);
    setAboutMeDiffOpened(false);
  }

  async function handleEditAboutMeUpdate(val) {
    await setAboutMe(val);
    await setUpdatedAboutMe(val);
    await saveAboutMe(val);
    setEditAboutMeModalOpened(false);
  }

  async function saveAboutMe(updatedAboutMe) {
    // BUG: Why can't I pass the UID?? It fails to render because undefined, but here it is defined. 
    const path = aboutMePath(user.uid);
    const docRef = doc(firestore, path);
    setDoc(docRef, {
      aboutMe: updatedAboutMe,
      submitted: serverTimestamp(),
    }).then(() => {
      console.log(`Saved aboutMe text to :\n${path}`);
    });
  }

  const remoteExtract = httpsCallable(functions, 'extract');

  async function fetchAboutMe() {
    if (user && aboutMe == "") {
      const path = aboutMePath(user.uid);
      return (await getDoc(doc(firestore, path))).get('aboutMe');
    }
    return "";
  }

  async function fetchPrompt() {
    if (user && prompt == "") {
      const path = promptPath(user.uid);
      return (await getDoc(doc(firestore, path))).get('prompt');
    }
    return "";
  }

  async function updateAboutMe() {
    const extractResponse = await remoteExtract({ 'journal-text': journalText, 'previous-about-me': aboutMe, 'prompt-to-user': prompt })
    const updatedAboutMe = extractResponse.data.candidates[0];

    // Used in the diff modal
    setUpdatedAboutMe(updatedAboutMe);
  }

  async function promptDiffBeforeSave() {
    setAboutMeDiffOpened(true);
  }

  async function updatePrompt() {
    setPrompt("Loading...")
    const promptResponse = await remotePrompt({ 'about-me': aboutMe })
    if (promptResponse.data && promptResponse.data.candidates.length > 0) {
      const prompt = promptResponse.data.candidates[0];
      setPrompt(prompt);
      savePrompt(prompt);
    }
  }

  async function savePrompt(prompt) {
    const path = promptPath(user.uid);
    const docRef = doc(firestore, path);
    setDoc(docRef, {
      prompt: prompt,
      submitted: serverTimestamp(),
    }).then(() => {
      console.log(`Saved prompt to: ${path}`);
    });
  }

  useEffect(() => {
    fetchAboutMe().then((s) => { setAboutMe(s) });
    fetchPrompt().then((s) => { setPrompt(s) });
  }, [user])

  return (
    <UserContext.Provider value={{ userStatus: userStatus, user: user }} >
      <div className="Journal-container">
        <WelcomeModal />
        <EditAboutMeModal
          open={editAboutMeModalOpened}
          handleCancel={() => setEditAboutMeModalOpened(false)}
          handleUpdate={handleEditAboutMeUpdate}
          value={aboutMe}
        />
        <AboutMeDiffModal
          open={aboutMeDiffOpened}
          before={aboutMe}
          after={updatedAboutMe}
          handleUpdate={handleAboutMeUpdate}
          handleCancel={handleAboutMeCancel} />
        <Title />
        <AboutMeContainer
          aboutMe={aboutMe}
          onClickUpdate={() => setEditAboutMeModalOpened(true)} />
        <PromptContainer handleClickGeneratePrompt={updatePrompt} prompt={prompt} />
        <JournalText handleJournalTextChange={(event) => setJournalText(event.target.value)} value={journalText} />
        <Button variant="contained" onClick={handleJournalSave} disabled={!user}>Save</Button>
      </div>
    </UserContext.Provider>
  );

}

export default Journal;
