import { getAuth, indexedDBLocalPersistence } from '@firebase/auth';
import { initializeFirestore } from '@firebase/firestore';
import { CssBaseline, ThemeProvider, createTheme } from '@mui/material';
import { getFunctions } from 'firebase/functions';
import { AuthProvider, FirebaseAppProvider, FirestoreProvider, FunctionsProvider, useFirebaseApp } from 'reactfire';
import './App.css';
import Journal from './Journal';


const firebaseConfig = {
  apiKey: "AIzaSyDB9L1Iuac4cEqHLwkd5ex126Fwg3TTFf8",
  authDomain: "gemini-api-410203.firebaseapp.com",
  projectId: "gemini-api-410203",
  storageBucket: "gemini-api-410203.appspot.com",
  messagingSenderId: "588582383260",
  appId: "1:588582383260:web:8515873cda5788ac70c759",
  measurementId: "G-R3B5JVWLGD"
};

const darkTheme = createTheme({
  palette: {
    mode: 'dark',
    background: {
      default: 'rgb(26, 28, 29)'
    },
    '@global': {
      '&::-webkit-scrollbar': {
        backgroundColor: '#202324',
        color: '#aba499'
      },
      '&::-webkit-scrollbar-thumb': {
        backgroundColor: '#454a4d',
      },
      '&::-webkit-scrollbar-corner': {
        backgroundColor: '#181a1b',
      }
    },
  },

  components: {
    MuiButton: {
      styleOverrides: {
        root: {
          backgroundColor: 'rgb(6, 66, 115)',
          color: 'white',
          '&:hover': {
            backgroundColor: 'rgb(9, 93, 162)'
          }
        }
      }
    },
  },

})

function App() {
  return (
    <ThemeProvider theme={darkTheme}>
      <CssBaseline>
        <FirebaseAppProvider firebaseConfig={firebaseConfig}>
          <FirebasedJournal />
        </FirebaseAppProvider>
      </CssBaseline>
    </ThemeProvider>
  );
}

function FirebasedJournal() {
  const app = useFirebaseApp();
  const firestoreInstance = initializeFirestore(app, { localCache: indexedDBLocalPersistence });
  const functions = getFunctions(app);
  const auth = getAuth(app);

  return (
    <FunctionsProvider sdk={functions}>
      <AuthProvider sdk={auth}>
        <FirestoreProvider sdk={firestoreInstance}>
          <Journal />
        </FirestoreProvider>
      </AuthProvider>
    </FunctionsProvider>
  )
}

export default App;
