import { GoogleAuthProvider, getAuth, signInWithPopup, signOut } from '@firebase/auth';
import { useContext } from 'react';
import { useFirebaseApp } from 'reactfire';
import './User.css';
import { UserContext } from './UserContext';

function UserDisplay() {
    const app = useFirebaseApp();
    const auth = getAuth(app);
    const provider = new GoogleAuthProvider();

    const userContext = useContext(UserContext);

    if (userContext.userStatus === 'loading') {
        return (<div>Loading..."</div>);
    }

    async function handleSigninClick() {
        return await signInWithPopup(auth, provider);
    }

    async function handleSignOutClick() {
        return await signOut(auth);
    }

    if (!userContext.user) {
        return (<div className='User-row'>User is nil: <a onClick={() => handleSigninClick()}>signin</a></div>)
    }

    return (
        <div className='User-row'>User: {userContext.user.displayName} | <a onClick={() => handleSignOutClick()}>Sign Out</a></div>
    )
}

export default UserDisplay;