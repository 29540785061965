import { getFunctions } from '@firebase/functions';
import CachedIcon from '@mui/icons-material/Cached';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import { getFirestore } from 'firebase/firestore';
import { useFirebaseApp } from 'reactfire';
import './PromptContainer.css';

function PromptContainer(props) {
    const app = useFirebaseApp();
    const functions = getFunctions(app);
    const firestore = getFirestore(app);

    return (
        <div className="Prompt-row">
            <div className='Prompt-text'>
                <TextField
                    id="prompt"
                    label="Prompt"
                    multiline
                    minRows={2}
                    maxRows={2}
                    value={props.prompt}
                    inputMode='none'
                />
            </div>
            <Button variant="contained" onClick={props.handleClickGeneratePrompt}>
                <CachedIcon />
            </Button>
        </div>
    );
}


export default PromptContainer;