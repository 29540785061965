import { Button } from '@mui/material';
import TextField from '@mui/material/TextField';
import BasicModal from './BasicModal';
import './EditAboutMeModal.css';
import { useEffect, useState } from 'react';

export function EditAboutMeModal(props) {
    async function doNothing(val) { }
    const [aboutMe, setAboutMe] = useState('');

    useEffect(() => {
        setAboutMe(props.value)
    }, [])

    return (
        <BasicModal open={props.open} onChange={doNothing} title="Edit About Me" >
            <div className="EditAboutMeModal-container">
                <div className='EditAboutMeModal-row'>
                    <TextField className='EditAboutMeModal-text-box'
                        multiline
                        minRows={4}
                        value={aboutMe}
                        onChange={(e) => setAboutMe(e.target.value)}></TextField>
                </div>
                <div className='EditAboutMeModal-row'>
                    <Button variant="contained" onClick={props.handleCancel} className='EditAboutMeModal-button'>Cancel</Button>
                    <Button variant="contained" onClick={() => props.handleUpdate(aboutMe)} className='EditAboutMeModal-button'>Accept Update</Button>
                </div>
            </div>
        </BasicModal>
    );
}

