import Box from '@mui/material/Box';
import Modal from '@mui/material/Modal';
import Typography from '@mui/material/Typography';

const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: '80%',
    bgcolor: 'background.paper',
    border: '2px solid #000',
    boxShadow: 24,
    p: 4,
};

export default function BasicModal(props) {
    return (
        <Modal
            disableEnforceFocus
            open={props.open}
            onClose={() => props.onChange(false)}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
        >
            <Box sx={style}>
                <BasicModalTitle title={props.title} />
                <Typography id="modal-modal-description" sx={{ mt: 2 }}>
                    {props.children}
                </Typography>
            </Box>
        </Modal>
    );
}

function BasicModalTitle(props) {
    if (props.title) {
        return <Typography id="modal-modal-title" variant="h6" component="h2">
            {props.title}
        </Typography>
    } else {
        return
    }
}