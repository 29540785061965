import TextField from '@mui/material/TextField';

function JournalText(props) {
    return (
        <TextField
            name="journal-text"
            id="journal-text"
            label="Journal Text"
            multiline
            onChange={props.handleJournalTextChange}
            value={props.value}
            fullWidth
            rows={30}
            sx={{
                "overflow-y": "scroll",
            }}
        />
    );
}

export default JournalText; 