import './Title.css';
import UserDisplay from './User';

function Title() {
    return (
        <div className='Title-row'>
            <div className='Title-text'><b>{document.title}</b></div>
            <UserDisplay />
        </div>
    );
}

export default Title;