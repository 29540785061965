import { getFunctions, httpsCallable } from '@firebase/functions';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import { useFirebaseApp } from 'reactfire';
import './AboutMeContainer.css';
import { useState } from 'react';
import EditIcon from '@mui/icons-material/Edit';

function AboutMeContainer(props) {
    const app = useFirebaseApp();
    const functions = getFunctions(app);

    return (<div className="About-Me-row" >
        <TextField
            className="About-Me-text" id="about-me"
            label="About Me"
            multiline
            value={props.aboutMe} />
        <AboutMeButton onClick={props.onClickUpdate} />
    </div>);
}

function AboutMeButton(props) {
    return (
        <Button variant="contained" onClick={props.onClick}>
            <EditIcon />
        </Button>
    );
}
export default AboutMeContainer;